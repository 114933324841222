import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { API_URL } from "../Api/Config";

const SideBar = () => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [isSubadmin, setIsSubAdmin] = useState({
    banner: false,
    home: false,
    orders: false,
    products: false,
    setting: false,
    transaction:true
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.startsWith("/product")) {
      setOpenDropdown("products");
    } else if (location.pathname.startsWith("/banners")) {
      setOpenDropdown("banners");
    } else if (location.pathname.startsWith("/settings")) {
      setOpenDropdown("settings");
    } else if (location.pathname.startsWith("/transaction")) {
    }
  }, [location.pathname]);

  const toggleDropdown = (dropdownName: string) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const handleNavOrder = () => {
    setOpenDropdown(null);
    navigate("/order");
  };

  const handleNavTransaction = () => {
    setOpenDropdown(null);
    navigate("/transaction");
  };

  const successMessage = (content: string) => {
    message.success(content);
  };

  const errorMessage = (content: string) => {
    message.error(content);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/user/auth/admin/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dashtoken")}`,
          },
        }
      );

      if (response.status === 200) {
        localStorage.clear();
        successMessage("Logout successful");
        window.location.reload();
        navigate("/");
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        errorMessage(
          error.response?.data?.message || "An error occurred during logout."
        );
      } else {
        errorMessage("An unexpected error occurred.");
      }
    }
  };

  const getME = async () => {
    const subAdminToken = localStorage.getItem("subadmintoken");
    try {
      const res = await axios.get(`${API_URL}/api/user/getMe`, {
        headers: {
          Authorization: `Bearer ${subAdminToken}`,
        },
      });

      setIsSubAdmin(res.data.user.access);

      if (subAdminToken) {
        localStorage.setItem("dashtoken", subAdminToken);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const userRole = localStorage.getItem("role");
  useEffect(() => {
    const userRole = localStorage.getItem("role");
    if (userRole === "subadmin") {
      getME();
    }
  }, []);

  return (
    <section className="sidebar-container">
      <div>
        <div className="logo">
          <img src="/assets/logo.png" alt="img" className="img-fluid" />
        </div>
        {userRole === "subadmin" ? (
          <>
            <div className="pt-1 side-bar-contain">
              {/* Home Menu */}
              {isSubadmin?.home && (
                <div
                  className={`side-icon-combine ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <div className="icon-side">
                    <i className="fi fi-rs-house-chimney"></i>
                  </div>
                  <div className="side-content-1">
                    <Link to="/" className="link">
                      Home
                    </Link>
                  </div>
                </div>
              )}
              {/* Products Menu */}
              {isSubadmin?.products && (
                <div className="side-total">
                  <div
                    className={`side-icon-combine ${
                      location.pathname.startsWith("/product") ? "active" : ""
                    }`}
                    onClick={() => toggleDropdown("products")}
                  >
                    <div className="icon-side">
                      <i className="fi fi-rs-box-open"></i>
                    </div>

                    <div className="side-content">Products</div>

                    <div className="dropdown-icon ms-1">
                      {openDropdown === "products" ? (
                        <i className="fi fi-rs-caret-up"></i>
                      ) : (
                        <i className="fi fi-rs-caret-down"></i>
                      )}
                    </div>
                  </div>
                  {openDropdown === "products" && (
                    <div className="home-dropdown-combine">
                      <div className="home-dropdown-content">
                        <Link
                          to="/product/productlist"
                          className={`file ${
                            location.pathname === "/product/productlist"
                              ? "file-active"
                              : "file"
                          }`}
                        >
                          Product listing
                        </Link>
                        <Link
                          to="/product/addproducts"
                          className={`file ${
                            location.pathname === "/product/addproducts"
                              ? "file-active"
                              : "file"
                          }`}
                        >
                          Add Products
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* Orders Menu */}
              {isSubadmin?.orders && (
                <div
                  className={`side-icon-combine ${
                    location.pathname === "/order" ? "active" : ""
                  }`}
                  onClick={handleNavOrder}
                >
                  <div className="icon-side">
                    <i className="fi fi-rs-shopping-cart"></i>
                  </div>
                  <div className="side-content-1">Orders</div>
                </div>
              )}
               {isSubadmin?.transaction && (
                <div
                  className={`side-icon-combine ${
                    location.pathname === "/transaction" ? "active" : ""
                  }`}
                  onClick={handleNavTransaction}
                >
                  <div className="icon-side">
                    <i className="fi fi-rs-shopping-cart"></i>
                  </div>
                  <div className="side-content-1">Transcation</div>
                </div>
              )}
              {/* Banners Menu */}
              {isSubadmin?.banner && (
                <div className="side-total">
                  {/* <div className="icon-side">
                <i className="fi fi-rs-ad"></i>
              </div> */}
                  {/* <Link to="/banners" style={{ textDecoration: "none" }} >
                <div className="side-content">Banner</div>
              </Link> */}

                  <div
                    className={`side-icon-combine ${
                      location.pathname.startsWith("/banners") ? "active" : ""
                    }`}
                    onClick={() => toggleDropdown("banners")}
                  >
                    <div className="icon-side">
                      <i className="fi fi-rs-settings"></i>
                    </div>

                    <div className="side-content">Banners</div>

                    <div className="dropdown-icon">
                      {openDropdown === "settings" ? (
                        <i className="fi fi-rs-caret-up"></i>
                      ) : (
                        <i className="fi fi-rs-caret-down"></i>
                      )}
                    </div>
                  </div>

                  {openDropdown === "banners" && (
                    <div className="home-dropdown-combine">
                      <div className="home-dropdown-content">
                        <Link
                          to="/banners/home-banner"
                          className={`file ${
                            location.pathname === "/banners/home-banner"
                              ? "file-active"
                              : "file"
                          }`}
                        >
                          Hero Banners
                        </Link>
                        <Link
                          to="/banners/home-offer"
                          className={`file ${
                            location.pathname === "/banners/home-offer"
                              ? "file-active"
                              : "file"
                          }`}
                        >
                          Home Offers
                        </Link>
                        <Link
                          to="/banners/offer-Banner"
                          className={`file ${
                            location.pathname === "/banners/offer-Banner"
                              ? "file-active"
                              : "file"
                          }`}
                        >
                          Offers Banner
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* Settings Menu */}
              {isSubadmin?.setting && (
                <div className="gaping-side">
                  <div className="side-total">
                    <div
                      className={`side-icon-combine ${
                        location.pathname.startsWith("/settings")
                          ? "active"
                          : ""
                      }`}
                      onClick={() => toggleDropdown("settings")}
                    >
                      <div className="icon-side">
                        <i className="fi fi-rs-settings"></i>
                      </div>

                      <div className="side-content">Settings</div>

                      <div className="dropdown-icon">
                        {openDropdown === "settings" ? (
                          <i className="fi fi-rs-caret-up"></i>
                        ) : (
                          <i className="fi fi-rs-caret-down"></i>
                        )}
                      </div>
                    </div>

                    {openDropdown === "settings" && (
                      <div className="home-dropdown-combine">
                        <div className="home-dropdown-content">
                          <Link
                            to="/settings/profile"
                            className={`file ${
                              location.pathname === "/settings/profile"
                                ? "file-active"
                                : "file"
                            }`}
                          >
                            Profile
                          </Link>
                          <Link
                            to="/settings/domain"
                            className={`file ${
                              location.pathname === "/settings/domain"
                                ? "file-active"
                                : "file"
                            }`}
                          >
                            Domain
                          </Link>
                          <Link
                            to="/settings/subadmin"
                            className={`file ${
                              location.pathname === "/settings/subadmin"
                                ? "file-active"
                                : "file"
                            }`}
                          >
                            Subadmin
                          </Link>
                          <Link
                            to="/settings/set-estimate-delivery"
                            className={`file ${
                              location.pathname ===
                              "/settings/set-estimate-delivery"
                                ? "file-active"
                                : "file"
                            }`}
                          >
                            Set Estimate
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}{" "}
              {/* Logout */}
              <div className={`side-icon-combine`} onClick={handleLogout}>
                <div className="icon-side">
                  <i className="fi fi-rs-sign-out-alt"></i>
                </div>
                <div className="side-content-1">Logout</div>
              </div>
            </div>
          </>
        ) : (
          <div className="pt-1 side-bar-contain">
            {/* Home Menu */}
            <div
              className={`side-icon-combine ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              <div className="icon-side">
                <i className="fi fi-rs-house-chimney"></i>
              </div>
              <div className="side-content-1">
                <Link to="/" className="link">
                  Home
                </Link>
              </div>
            </div>

            {/* Products Menu */}
            <div className="side-total">
              <div
                className={`side-icon-combine ${
                  location.pathname.startsWith("/product") ? "active" : ""
                }`}
                onClick={() => toggleDropdown("products")}
              >
                <div className="icon-side">
                  <i className="fi fi-rs-box-open"></i>
                </div>

                <div className="side-content">Products</div>

                <div className="dropdown-icon ms-1">
                  {openDropdown === "products" ? (
                    <i className="fi fi-rs-caret-up"></i>
                  ) : (
                    <i className="fi fi-rs-caret-down"></i>
                  )}
                </div>
              </div>
              {openDropdown === "products" && (
                <div className="home-dropdown-combine">
                  <div className="home-dropdown-content">
                    <Link
                      to="/product/productlist"
                      className={`file ${
                        location.pathname === "/product/productlist"
                          ? "file-active"
                          : "file"
                      }`}
                    >
                      Product listing
                    </Link>
                    <Link
                      to="/product/addproducts"
                      className={`file ${
                        location.pathname === "/product/addproducts"
                          ? "file-active"
                          : "file"
                      }`}
                    >
                      Add Products
                    </Link>
                  </div>
                </div>
              )}
            </div>

            {/* Orders Menu */}
            <div
              className={`side-icon-combine ${
                location.pathname === "/order" ? "active" : ""
              }`}
              onClick={handleNavOrder}
            >
              <div className="icon-side">
                <i className="fi fi-rs-shopping-cart"></i>
              </div>
              <div className="side-content-1">Orders</div>
            </div>


            <div
                  className={`side-icon-combine ${
                    location.pathname === "/transaction" ? "active" : ""
                  }`}
                  onClick={handleNavTransaction}
                >
                  <div className="icon-side">
                    <i className="fi fi-rs-shopping-cart"></i>
                  </div>
                  <div className="side-content-1">Transaction</div>
                </div>

            {/* Banners Menu */}
            <div className="side-total">
              {/* <div className="icon-side">
                <i className="fi fi-rs-ad"></i>
              </div> */}
              {/* <Link to="/banners" style={{ textDecoration: "none" }} >
                <div className="side-content">Banner</div>
              </Link> */}

              <div
                className={`side-icon-combine ${
                  location.pathname.startsWith("/banners") ? "active" : ""
                }`}
                onClick={() => toggleDropdown("banners")}
              >
                <div className="icon-side">
                  <i className="fi fi-rs-settings"></i>
                </div>

                <div className="side-content">Banners</div>

                <div className="dropdown-icon">
                  {openDropdown === "settings" ? (
                    <i className="fi fi-rs-caret-up"></i>
                  ) : (
                    <i className="fi fi-rs-caret-down"></i>
                  )}
                </div>
              </div>

              {openDropdown === "banners" && (
                <div className="home-dropdown-combine">
                  <div className="home-dropdown-content">
                    <Link
                      to="/banners/home-banner"
                      className={`file ${
                        location.pathname === "/banners/home-banner"
                          ? "file-active"
                          : "file"
                      }`}
                    >
                      Hero Banners
                    </Link>
                    <Link
                      to="/banners/home-offer"
                      className={`file ${
                        location.pathname === "/banners/home-offer"
                          ? "file-active"
                          : "file"
                      }`}
                    >
                      Home Offers
                    </Link>
                    <Link
                      to="/banners/offer-Banner"
                      className={`file ${
                        location.pathname === "/banners/offer-Banner"
                          ? "file-active"
                          : "file"
                      }`}
                    >
                      Offers Banner
                    </Link>
                  </div>
                </div>
              )}
            </div>

            {/* Settings Menu */}
            <div className="gaping-side">
              <div className="side-total">
                <div
                  className={`side-icon-combine ${
                    location.pathname.startsWith("/settings") ? "active" : ""
                  }`}
                  onClick={() => toggleDropdown("settings")}
                >
                  <div className="icon-side">
                    <i className="fi fi-rs-settings"></i>
                  </div>

                  <div className="side-content">Settings</div>

                  <div className="dropdown-icon">
                    {openDropdown === "settings" ? (
                      <i className="fi fi-rs-caret-up"></i>
                    ) : (
                      <i className="fi fi-rs-caret-down"></i>
                    )}
                  </div>
                </div>

                {openDropdown === "settings" && (
                  <div className="home-dropdown-combine">
                    <div className="home-dropdown-content">
                      <Link
                        to="/settings/profile"
                        className={`file ${
                          location.pathname === "/settings/profile"
                            ? "file-active"
                            : "file"
                        }`}
                      >
                        Profile
                      </Link>
                      <Link
                        to="/settings/domain"
                        className={`file ${
                          location.pathname === "/settings/domain"
                            ? "file-active"
                            : "file"
                        }`}
                      >
                        Domain
                      </Link>
                      <Link
                        to="/settings/subadmin"
                        className={`file ${
                          location.pathname === "/settings/subadmin"
                            ? "file-active"
                            : "file"
                        }`}
                      >
                        Subadmin
                      </Link>
                      <Link
                        to="/settings/set-estimate-delivery"
                        className={`file ${
                          location.pathname ===
                          "/settings/set-estimate-delivery"
                            ? "file-active"
                            : "file"
                        }`}
                      >
                        Set Estimate
                      </Link>
                    </div>
                  </div>
                )}
              </div>

              {/* Logout */}
              <div className={`side-icon-combine`} onClick={handleLogout}>
                <div className="icon-side">
                  <i className="fi fi-rs-sign-out-alt"></i>
                </div>
                <div className="side-content-1">Logout</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SideBar;
