import { Input, message } from "antd";
import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../Api/Config";
import { useNavigate } from "react-router-dom"; 
import { IoCaretBackOutline } from "react-icons/io5";

const Adddomain = () => {
  // State variable for domain details
  const [DomainDetails, setDomainDetails] = useState({
    companyName: "",
    domainName: "",
    location: "",
    contactPersonName: "",
    phoneNumber: ""
  });

  // Initialize useNavigate hook
  const navigate = useNavigate();

  // Handler to update individual fields in DomainDetails
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDomainDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleDomainadd = async () => {
    const { companyName, domainName, location, contactPersonName, phoneNumber } = DomainDetails;

    if (!companyName || !domainName || !location || !contactPersonName || !phoneNumber) {
      message.warning("Please fill in all fields.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/api/domains/add`,
        DomainDetails,
        {
          headers: {
            "Content-Type": "application/json",
             Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          },
        }
      );
        // message.success("Domain added successfully!");
        message.success("Dmomain Added Successfully");
        // Clear input fields on successful add
        setDomainDetails({
          companyName: "",
          domainName: "",
          location: "",
          contactPersonName: "",
          phoneNumber: ""
        });
        // Redirect to the domains page
        navigate("/settings/domain"); // Redirect to the domain list page
    } catch (error) {
      message.error("An error occurred. Please try again.");
      console.error("Domain adding error:", error);
    }
  };

  return (
    <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
      <Container>
        <div>
        
            <div className="head-txt d-flex">
                      <a  onClick={() => window.history.back()} className="icons-div" style={{textDecoration:"none",cursor:"pointer",color:"#000"}}>
                      <IoCaretBackOutline className="mb-1"/>
                      <span>Add Domain</span>
                      </a>
                     
                    </div>
        

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="mt-4">
                <label className="brand-label">Company Name*</label><br />
                <Input 
                  className="brand-input" 
                  name="companyName"
                  value={DomainDetails.companyName} 
                  onChange={handleChange} 
                />
                <div className="mt-lg-3">
                  <label className="brand-label">Domain Name*</label><br />
                  <Input 
                    className="brand-input" 
                    name="domainName"
                    value={DomainDetails.domainName} 
                    onChange={handleChange} 
                  />
                </div>
                <div className="mt-lg-3">
                  <label className="brand-label">Location*</label><br />
                  <Input 
                    className="brand-input" 
                    name="location"
                    value={DomainDetails.location} 
                    onChange={handleChange} 
                  />
                </div>
                <div className="mt-lg-3">
                  <label className="brand-label">Contact Person Name*</label><br />
                  <Input 
                    className="brand-input" 
                    name="contactPersonName"
                    value={DomainDetails.contactPersonName} 
                    onChange={handleChange} 
                  />
                </div>
                <div className="mt-lg-3">
                  <label className="brand-label">Phone No*</label><br />
                  <Input 
                    className="brand-input" 
                    name="phoneNumber"
                    value={DomainDetails.phoneNumber} 
                    onChange={handleChange} 
                  />
                </div>
              </div>
            </div>

            <div className="button-settings">
              <Button className="btn-2">Cancel</Button>
              <Button onClick={handleDomainadd} className="btn-1">Save</Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Adddomain;
