import { Button, DatePicker, Input, Select, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Container, Dropdown } from "react-bootstrap";
import { API_URL } from "../Api/Config";
import type { TableColumnsType, TableProps } from "antd";
import { IoSearch } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import { Box, Modal, Paper, TableCell, TableContainer, TableHead, Table as TableM, TableRow } from "@mui/material";
import html2pdf from "html2pdf.js";

interface DataType {
  transactionId: any;
  key: React.Key;
  Orderid: string;
  OrderedDate: string;
  Productname: string;
  specs: string;
  TotalPrize: string;
  mrpPrice: string;
  Shippingaddress: string;
  name: string;
  Payment: string;
  orderStatus: string;
  rating: number;
}

interface ShippingAddress {
  firstName: string;
  lastName: string;
  houseNumberAndFloor: string;
  locality: string;
  landmark: string;
  pincode: string;
  phoneNumber: string;
}



interface OrderData {
  transactionId: string;
  _id: string;
  shippingAddress: ShippingAddress;
  products: [
    {
      _id: string;
      productPrice: number;
      discountedPrice: number;
      discountInPercent: number;
      quantity: number;
      storage: string;
      categories: string;
      colorName: string;
      variantId: string;
      ram: string;
    }
  ];
  paymentAmount: number;
  paymentMethod: string;
  paymentStatus: string;
  exchange: boolean;
  orderDate: string;
  deliveryType: string;
  deliveryDate: string;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "90%",
  overflowY: "scroll",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
};

const Transaction = () => {
  const [orders, setOrders] = useState<DataType[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<DataType[]>([]);
  const [orderIdFilter, setOrderIdFilter] = useState<string>('');
  const [ratingFilter, setRatingFilter] = useState<string | number | undefined>(undefined);
  const [deliveredDateFilter, setDeliveredDateFilter] = useState<any>(null);
  const [transactionIdfilter, setTransactionIdfilter] = useState<any>(null);
  const [ogData, setOgData] = useState<OrderData[]>([]);
  const [viewDta, setViewDta] = useState<OrderData[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [istotalPrice,setIstotalPrice]=useState("")

  const handleViewOrders = (id: any) => {
    const filterdts = ogData.filter((val) => val._id === id.Orderid);
    console.log(filterdts,"filterdts");
    setViewDta(filterdts);
    if (viewDta) {
      handleOpen();
    }
  };

  useEffect(() => {
    // Fetch orders with status "completed"
    axios.get(`${API_URL}/api/orders/?status=completed`,{
      headers:{
         Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
      }
    })
      .then((response) => {
        const fetchedOrders = response.data.data.orders.map((order: any) => ({
          key: order._id,
          Orderid: order._id,
          OrderedDate: new Date(order.orderDate).toLocaleDateString(),
          Productname: order.products[0]?.productName || "N/A",
          specs: `RAM: ${order.products[0]?.ramSize}, Storage: ${order.products[0]?.storage}, Color: ${order.products[0]?.colorName}`,
          TotalPrize: order.finalAmount.toFixed(2),
          mrpPrice: order.totalAmount.toFixed(2),
          Shippingaddress: `${order.shippingAddress.houseNumberAndFloor||""}, ${order.shippingAddress.locality||""}, ${order.shippingAddress.pincode||""}`,
          name: `${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`,
          Payment: order.paymentMethod.toUpperCase(),
          orderStatus: order.status,
          rating: order.rating || 0, 
          transactionId:order.transactionId||"-"
        }));
        const totalPrice = fetchedOrders.reduce((sum:any, order:any) => sum + parseFloat(order.TotalPrize), 0);
        setIstotalPrice(totalPrice)
        setOgData(response.data.data.orders)
        setOrders(fetchedOrders);
        setFilteredOrders(fetchedOrders); 
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);

  const handleFilterChange = () => {
    let filtered = [...orders];

    // Filter by Order ID
    if (orderIdFilter) {
      filtered = filtered.filter((order) =>
        order.Orderid.toLowerCase().includes(orderIdFilter.toLowerCase())
      );
    }

   
    if (transactionIdfilter) {
      filtered = filtered.filter((order) => order.transactionId === transactionIdfilter);
    }

    // Filter by Delivered Date (ensure proper date comparison)
    if (deliveredDateFilter) {
      const formattedFilterDate = new Date(deliveredDateFilter).toLocaleDateString();
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.OrderedDate).toLocaleDateString();
        return orderDate === formattedFilterDate;
      });
    }

    // Update filtered orders
    setFilteredOrders(filtered);
  };

  const handleReset = () => {
    setOrderIdFilter('');
    setTransactionIdfilter("")
    setRatingFilter(undefined);
    setDeliveredDateFilter(null);
    setFilteredOrders(orders);
  };

  const rowSelection: TableProps<any>["rowSelection"] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows:", selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", 
      name: record.name,
    }),
  };

  
  const columns: TableColumnsType<DataType> = [
    {
      title: "Order ID",
      dataIndex: "Orderid",
      render: (text: string) => <p className="mb-0" style={{ color: "#444444" }}>{text.slice(-5)}</p>,
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      render: (text: string) => <p className="mb-0" style={{ color: "#444444" }}>{text?text:"-"}</p>,
    },
    {
      title: "Product Name",
      dataIndex: "Productname",
      render: (text: string, record) => (
        <>
          <p className="mb-0" style={{ color: "#444444" }}>{text}</p>
          {/* <span style={{ fontSize: "12px", fontWeight: 400, color: "#6C6C6C" }}>( {record.specs} )</span> */}
        </>
      ),
    },
    {
      title: "Total Price",
      dataIndex: "TotalPrize",
      render: (text: string, record) => (
        <>
          <p className="mb-0" style={{ color: "#444444" }}>₹ {text}</p>
        </>
      ),
    },
    {
      title: "View All",
      dataIndex: "Orderid",
      key: "Orderid",
      render: (Orderid:any,record:any) => (
        <span
          onClick={() => handleViewOrders(record)}
          style={{
            color: "green",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          View
        </span>
      ),
    },
    {
      title: "Shipping Address",
      dataIndex: "name",
      render: (text: string, record) => (
        <>
          <p className="mb-0" style={{ color: "#444444" }}>{text}</p>
          <span style={{ fontSize: "12px", fontWeight: 400, color: "#6C6C6C" }} >
            {record.Shippingaddress}
          </span>
        </>
      ),
    }
  ];

  const pdfRef = useRef<HTMLDivElement | null>(null);

  const handleDownloadPdf = () => {
    const element = pdfRef.current;
    const options = {
      margin: 0.5,
      filename: `Order_${viewDta[0]?._id.slice(-5)}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  return (
    <>
       <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="head-txt pb-2">Transaction</h2>
            <h2 className="head-txt pb-2">Total Revenue:₹ {istotalPrice||"-"}</h2>
            </div>
         
      {/* <div className="d-flex justify-content-end mt-3">
        <Dropdown>
          <Dropdown.Toggle className="btn-down">Download</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#">Order</Dropdown.Item>
            <Dropdown.Item href="#">Invoice</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}

      <div className="select-combine mt-1">
        <div>
          <label className="brand-label">Order ID</label><br />
          <Input
            className="select-brand"
            placeholder="Enter Order ID"
            value={orderIdFilter}
            onChange={(e) => setOrderIdFilter(e.target.value)}
          />
        </div>
        <div>
          <label className="brand-label">Transaction ID</label><br />
          <Input
            className="select-brand"
            placeholder="Enter Transaction ID"
            value={transactionIdfilter}
            onChange={(e) => setTransactionIdfilter(e.target.value)}
          />
        </div>


        <div>
          <label className="brand-label">Delivered Date</label><br />
          <DatePicker
            className="select-brand"
            value={deliveredDateFilter}
            onChange={(date) => setDeliveredDateFilter(date)}
          />
        </div>

        <div style={{ marginTop: "28px" }}>
        <Button className="btn-3" onClick={handleFilterChange}>
          <IoSearch />
          </Button>
          <Button className="btn-3"  style={{ marginLeft: "10px" }} onClick={handleReset}><GrPowerReset />
          </Button>
        </div>
      </div>

      <div className="pt-3">
        <Table<DataType>
          className="custom-table"
          rowSelection={{ ...rowSelection }}

          columns={columns}
          pagination={{ pageSize: 10 }}
          dataSource={filteredOrders} 
        />
      </div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <div  ref={pdfRef}>
              <div className="d-flex justify-content-between pb-3">
                <div>
                  <h3 style={{ fontSize: "18px", fontWeight: 600 }}>
                    Order Details
                  </h3>

                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Order ID:</strong>{" "}
                    {viewDta[0]?._id.slice(-5)}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Order Date:</strong>{" "}
                    {new Date(viewDta[0]?.orderDate).toLocaleDateString()}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Amount:</strong>{" "}
                    ₹ {viewDta[0]?.paymentAmount}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Method:</strong>{" "}
                    {viewDta[0]?.paymentMethod}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Status:</strong>{" "}
                    {viewDta[0]?.paymentStatus}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Exchange Type:</strong>{" "}
                    {viewDta[0]?.exchange === true
                      ? "With Exchange"
                      : "Without exchange"}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Delivery Type:</strong>{" "}
                    {viewDta[0]?.deliveryType}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Delivery Date:</strong>{" "}
                    {new Date(viewDta[0]?.deliveryDate).toLocaleDateString()}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Transaction ID:</strong>{" "}
                    {viewDta[0]?.transactionId||"-"}
                  </p>
                </div>

                <div>
                  <h3
                    className=""
                    style={{ fontSize: "18px", fontWeight: 600 }}
                  >
                    Shipping Address
                  </h3>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.firstName}{" "}
                    {viewDta[0]?.shippingAddress?.lastName}
                  </p>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.houseNumberAndFloor}
                  </p>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.locality}
                  </p>
                  <p className="mb-0">{viewDta[0]?.shippingAddress?.pincode}</p>
                </div>
              </div>
              <TableContainer component={Paper}>
                <TableM aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product ID</TableCell>
                      <TableCell>Product Price</TableCell>
                      <TableCell>DiscountedPrice Price</TableCell>
                      <TableCell>discountInPercent Price</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Storage</TableCell>
                      <TableCell>Categories</TableCell>
                      <TableCell>Color</TableCell>
                      {/* <TableCell align="right">variantId</TableCell> */}
                      <TableCell>Ram</TableCell>
                    </TableRow>
                  </TableHead>

                  {viewDta.length > 0 &&
                    viewDta[0].products?.map((row: any) => (
                      <>
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row._id.slice(-5)}
                          </TableCell>
                          <TableCell align="center">
                          ₹ {row.productPrice ? row.productPrice : "-"}
                          </TableCell>
                          <TableCell align="center">
                          ₹ {row.discountedPrice ? row.discountedPrice : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.discountInPercent
                              ? row.discountInPercent
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.quantity ? row.quantity : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.storage ? row.storage : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.categories ? row.categories : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.colorName ? row.colorName : "-"}
                          </TableCell>
                          {/* <TableCell align="right">
      {row?.variantId.slice(-5)}
    </TableCell> */}
                          <TableCell align="center">
                            {row.ram ? row.ram : "-"}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableM>
              </TableContainer>
              </div>
              <div className="text-center py-3">
                <Button onClick={handleClose} style={{width:"100px",backgroundColor:"#000",color:"#fff",border:"1px solid #000"}} className="me-1">Close</Button>
                <Button onClick={handleDownloadPdf} style={{width:"100px",backgroundColor:"#000",color:"#fff",border:"1px solid #000"}} className="ms-1">Download</Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      </Container>
      </section>
    </>
  );
};

export default Transaction;
