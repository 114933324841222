import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { MdMailOutline } from "react-icons/md";
import { IoEyeOff } from "react-icons/io5";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../Api/Config";
const SubAdmin = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
 
  const gotoadminlogin=()=>{
    navigate('/');
  }

const handleLogin = async () => {

  try {
    const response = await axios.post(
      `${API_URL}/api/subadmin/auth/login`,
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
          
           
          
        },
      }
    );

    if (response.status === 200) {
      const { token } = response.data;
      const role = "subadmin";
      message.success("Login successful!");
      localStorage.setItem("subadmintoken", token);
      localStorage.setItem("role", role)
      window.location.reload();
      navigate("/");
    } else {
      message.error(response.data.message || "Login failed.");
    }
  } catch (error) {
    message.error("An error occurred. Please try again.");
    console.error("Login error:", error);
  }
};


  return (
    <>
      <section>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="login-1">
                <div className="login-logo">
                  <img
                    src="/assets/logo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="login-form">
                <div  style={{width:"50%"}}>
                  <h2 className="head-txt text-center">Welcome SubAdmin!</h2>
                  <h6 className="login-txt-1">Enter Email Id & Password</h6>
                  <p className="login-txt-2">
                    Enter your official Email id & Password
                  </p>

                  <div className="login-div">
                    <label>Email Id</label>
                    <br />
                    <Input
                      size="large"
                      suffix={<MdMailOutline />}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="login-div mt-2">
                    <label>Password</label>
                    <br />
                    <Input
                      size="large"
                      type="password"
                      suffix={<IoEyeOff />}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div>
                    <p
                      className="login-txt-2"
                      style={{
                        color: "#202224",
                        textDecoration: "1px underline #202224",
                        textAlign: "end",
                        cursor:"pointer"
                      }}
                    >
                      Forget password
                    </p>
                  </div>

                  <div className="d-flex justify-content-center mt-5">
                    <Button className="btn-1" onClick={handleLogin}>
                      Login
                    </Button>
                  </div>

                  <div className="mt-4">
                    <p onClick = {gotoadminlogin} className="login-txt-2">
                      Login as administrator{" "}
                      <span
                        style={{
                          color: "#202224",
                          textDecoration: "1px underline #202224",
                          cursor:"pointer"
                        }}
                      >
                        Login?
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubAdmin;
