import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import Button from "react-bootstrap/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Table } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../Api/Config";
import { message } from "antd";
import { IoCaretBackOutline } from "react-icons/io5";

const SetEstimateDelivery = () => {
  const [data, setData] = useState<any[]>([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      // Replace with your actual API endpoint
      const response = await axios.get(`${API_URL}/api/delivery-info`,{
        headers:{
           Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
        }
      });
      // Directly set the response data (which is an array of delivery info)
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch data from API when component mounts
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array to run only once when component mounts

  // Navigate to edit page with the id
  const handleEdit = (id: string) => {
    navigate(`/edit-delivery/${id}`);
  };

  // Delete delivery info
  const handleDelete = async (id: string) => {
    try {
      // Make an API call to delete the record
      await axios.delete(`${API_URL}/api/delivery-info/${id}`,{
        headers:{
           Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
        }
      });
      // Filter out the deleted record from the state
      setData(data.filter((item) => item._id !== id));
      message.success("Delivery Deleted");
    } catch (error) {
      console.error("Error deleting delivery info:", error);
    }
  };

  const handleNavigate = () => {
    navigate("/add-new-delivery");
  };

  return (
    <>
      <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
        <div className="head-txt d-flex">
        
            <span>Set Estimate Delivery</span>
         
        </div>

        <div className="select-combine justify-content-end align-items-end">
          <div className="mt-4">
            <Button className="btn-1" onClick={handleNavigate}>
              Add New
            </Button>
          </div>
        </div>

        <div className="pt-3">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="brand-table-head">S.No</TableCell>
                  <TableCell className="brand-table-head">State</TableCell>
                  <TableCell className="brand-table-head">District</TableCell>
                  <TableCell className="brand-table-head">Pincode</TableCell>
                  <TableCell className="brand-table-head">
                    Standard Delivery
                    <br />
                    Date
                  </TableCell>
                  <TableCell className="brand-table-head">
                    Express Delivery
                    <br />
                    Date
                  </TableCell>
                  <TableCell className="brand-table-head">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="brand-table-data"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell className="brand-table-data">
                      {row.state}
                    </TableCell>
                    <TableCell className="brand-table-data">
                      {row.district}
                    </TableCell>
                    <TableCell className="brand-table-data">
                      {row.pincode}
                    </TableCell>
                    <TableCell className="brand-table-data">
                      {row.standardDeliveryDate}
                    </TableCell>
                    <TableCell className="brand-table-data">
                      {row.expressDeliveryDate}
                    </TableCell>
                    <TableCell className="brand-table-data">
                      <div className="d-flex gap-2 justify-content-center">
                        <div>
                          <i
                            style={{ color: "green" }}
                            className="fi fi-rs-pencil edit"
                            onClick={() => handleEdit(row._id)} // Call handleEdit on edit icon click
                          ></i>
                        </div>
                        <div>
                          <i
                            style={{ color: "red" }}
                            className="fi fi-rs-trash edit"
                            onClick={() => handleDelete(row._id)} // Call handleDelete on delete icon click
                          ></i>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    </>
  );
};

export default SetEstimateDelivery;
