import React, { useState } from "react";
import { Input, message } from "antd";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../Api/Config";
import { IoCaretBackOutline } from "react-icons/io5";

const AddNewDelivery = () => {
  const [formData, setFormData] = useState({
    state: "",
    district: "",
    pincode: "",
    standardDeliveryDate: "",
    expressDeliveryDate: "",
  });

  const navigate = useNavigate(); // Initialize useNavigate hook

  // Handle input change
  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/api/delivery-info`,
        formData,{
          headers:{
             Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          }
        }
      );
      message.success("Delivery Data Added");
      navigate("/settings/set-estimate-delivery");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
      <div className="head-txt d-flex pb-3">
        <a
          onClick={() => window.history.back()}
          className="icons-div"
          style={{
            textDecoration: "none",
            cursor: "pointer",
            color: "#000",
          }}
        >
          <IoCaretBackOutline className="mb-1" />
          <span>Add Estimate Delivery</span>
        </a>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <label className="addbrand-label">State  <span style={{color:"red"}}>*</span></label>
          <br />
          <Input
            className="brand-input"
            style={{ width: "445px" }}
            name="state"
            value={formData.state}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">District  <span style={{color:"red"}}>*</span></label>
          <br />
          <Input
            className="brand-input"
            style={{ width: "445px" }}
            name="district"
            value={formData.district}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">Pincode  <span style={{color:"red"}}>*</span></label>
          <br />
          <Input
            className="brand-input"
            style={{ width: "445px" }}
            name="pincode"
            value={formData.pincode}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">Standard Delivery</label>
          <br />
          <Input
            className="brand-input"
            style={{ width: "445px" }}
            name="standardDeliveryDate"
            value={formData.standardDeliveryDate}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">Express Delivery <span style={{color:"red"}}>*</span></label>
          <br />
          <Input
            className="brand-input"
            style={{ width: "445px" }}
            name="expressDeliveryDate"
            value={formData.expressDeliveryDate}
            onChange={handleInputChange}
          />
        </div>

        <div className="mt-4">
          <Button type="submit" className="btn-1">
            Submit
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddNewDelivery;
