import { Switch } from "@mui/material";
import { Input, message, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { API_URL } from "../../Api/Config";
import { IoCaretBackOutline } from "react-icons/io5";
const { Option } = Select;

const label = { inputProps: { "aria-label": "Size switch demo" } };

interface Time {
  hr: string;
  min: string;
  sec: string;
}

interface PriceDetails {
  variant: string;
  mrp: string;
  currentPrice: string;
}

interface DealPriceDetails {
  variant: string;
  mrp: string;
  dealPrice: string;
}

interface FormData {
  categories: string;
  productName: string;
  productId: string;
  position: string;
  time: Time;
  currentPrice: PriceDetails;
  dealPrice: DealPriceDetails;
  isActive: boolean;
}

const AddnewDeal = () => {
  const [formData, setFormData] = useState<FormData>({
    categories: "",
    productName: "",
    productId: "",
    position: "",
    time: { hr: "", min: "", sec: "" },
    currentPrice: {
      variant: "",
      mrp: "",
      currentPrice: "",
    },
    dealPrice: {
      variant: "",
      mrp: "",
      dealPrice: "",
    },
    isActive: false,
  });

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [productvalues, setproductvalues] = useState<any[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/products`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dashtoken")}`,
          },
        });
        const dta = response.data.map((val: any) => {
          return val._id;
        });

        setproductvalues(dta);
      } catch (error) {
        console.error("Error fetching products", error);
        setproductvalues([]);
      }
    };
    fetchProducts();
  }, []);
  // Handle nested input changes for currentPrice and dealPrice
  const handleNestedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: "currentPrice" | "dealPrice" | "time"
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [name]: value,
      },
    }));
  };

  // Handle select changes
  const handleSelectChange = (name: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle switch toggle
  const handleToggle = () => {
    setFormData((prev) => ({
      ...prev,
      isActive: !prev.isActive,
    }));
  };

  const handleSubmit = async () => {
    const { productName, productId, categories, currentPrice, dealPrice } =
      formData;

    // Check if all required fields are filled
    if (
      !productName ||
      !productId ||
      !categories ||
      !currentPrice.currentPrice ||
      !dealPrice.dealPrice
    ) {
      message.error(
        "Please fill in all fields and provide prices for current and deal price."
      );
      return;
    }

    const DealData = {
      productName: formData.productName,
      productId: formData.productId,
      categories: formData.categories,
      time: {
        hr: formData.time.hr,
        min: formData.time.min,
        sec: formData.time.sec,
      },
      currentPrice: {
        currentPrice: formData.currentPrice.currentPrice,
        variant: formData.currentPrice.variant,
        mrp: formData.currentPrice.mrp,
      },
      DealPrice: {
        variant: formData.dealPrice.variant,
        mrp: formData.dealPrice.mrp,
        DealPrice: formData.dealPrice.dealPrice,
      },
    };

    try {
      // Send the form data to your API endpoint for adding the deal
      await axios.post(`${API_URL}/api/banner/dealOfTheDay`, DealData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("dashtoken")}`,
        },
      });
      message.success("Deal added successfully!");
    } catch (error) {
      console.error("Error while adding deal:", error);
      message.error("Failed to add deal.");
    }
  };
  return (
    <>
      <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
        <Container>
          <div className="d-flex justify-content-between mb-3">
            <div className="head-txt d-flex">
              <a
                onClick={() => window.history.back()}
                className="icons-div"
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#000",
                }}
              >
                <IoCaretBackOutline className="mb-1" />
                <span>Add New Deal</span>
              </a>
            </div>
          </div>
          <div className="addbanner">
            <label className="addbrand-label mt-3">Categories</label>
            <br />
            <Select
              style={{ width: "445px" }}
              value={formData.categories}
              onChange={(value) => handleSelectChange("categories", value)}
              className="select-brand-deal"
              options={[
                { value: "Smartphone", label: "Smartphone" },
                { value: "Tab", label: "Tab" },
                { value: "SmartWatch", label: "SmartWatch" },
                // { value: "WashingMachine", label: "WashingMachine" },
                // { value: "Fridge", label: "Fridge" },
              ]}
              suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
            />
            <br />
            <br />
            <label className="addbrand-label mt-3">Product Name</label>
            <br />
            <Input
              name="productName"
              value={formData.productName}
              onChange={handleChange}
              style={{ width: "445px" }}
            />
            <br />
            <label className="addbrand-label mt-3">Product Id</label>
            <br />
            <Select
              style={{ width: "445px" }}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, productId: value }))
              }
              className="select-brand-b"
              suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
              placeholder="Select a product"
              value={formData.productId}
            >
              {productvalues.map((product: any) => (
                <Option key={product} value={product}>
                  (ID: {product})
                </Option>
              ))}
            </Select>
            <br />
            <br />
            <label className="addbrand-label mt-3">Time</label>
            <br />
            <div className="d-flex">
              <Input
                name="hr"
                value={formData.time.hr}
                onChange={(e) => handleNestedChange(e, "time")}
                style={{ width: "100px", padding: "6px 10px", margin: "10px" }}
                placeholder="hr"
              />
              <span>hr</span>
              <Input
                name="min"
                value={formData.time.min}
                onChange={(e) => handleNestedChange(e, "time")}
                style={{ width: "100px", padding: "6px 10px", margin: "10px" }}
                placeholder="Min"
              />
              <span>Min</span>
              <Input
                name="sec"
                value={formData.time.sec}
                onChange={(e) => handleNestedChange(e, "time")}
                style={{ width: "100px", padding: "6px 10px", margin: "10px" }}
                placeholder="Sec"
              />
              <span>Sec</span>
            </div>
            <br />
            <label className="addbrand-label mt-3">Current Price</label>
            <div style={{ display: "flex" }}>
              <span>
                <h6 style={{ marginLeft: "10px" }}>Variant</h6>
                <Input
                  name="variant"
                  value={formData.currentPrice.variant}
                  onChange={(e) => handleNestedChange(e, "currentPrice")}
                  style={{
                    width: "100px",
                    padding: "6px 10px",
                    margin: "10px",
                  }}
                />
              </span>
              <br />
              <span>
                <h6 style={{ marginLeft: "10px" }}>M.R.P</h6>
                <Input
                  name="mrp"
                  value={formData.currentPrice.mrp}
                  onChange={(e) => handleNestedChange(e, "currentPrice")}
                  style={{
                    width: "100px",
                    padding: "6px 10px",
                    margin: "10px",
                  }}
                />
              </span>
              <br />
              <span>
                <h6 style={{ marginLeft: "10px" }}>Current Price</h6>
                <Input
                  name="currentPrice"
                  value={formData.currentPrice.currentPrice}
                  onChange={(e) => handleNestedChange(e, "currentPrice")}
                  style={{
                    width: "100px",
                    padding: "6px 10px",
                    margin: "10px",
                  }}
                />
              </span>
            </div>
            <br />
            <label className="addbrand-label mt-3">Deal Price</label>
            <div style={{ display: "flex" }}>
              <span>
                <h6 style={{ marginLeft: "10px" }}>Variant</h6>
                <Input
                  name="variant"
                  value={formData.dealPrice.variant}
                  onChange={(e) => handleNestedChange(e, "dealPrice")}
                  style={{
                    width: "100px",
                    padding: "6px 10px",
                    margin: "10px",
                  }}
                />
              </span>
              <br />
              <span>
                <h6 style={{ marginLeft: "10px" }}>M.R.P</h6>
                <Input
                  name="mrp"
                  value={formData.dealPrice.mrp}
                  onChange={(e) => handleNestedChange(e, "dealPrice")}
                  style={{
                    width: "100px",
                    padding: "6px 10px",
                    margin: "10px",
                  }}
                />
              </span>
              <br />
              <span>
                <h6 style={{ marginLeft: "10px" }}>Deal Price</h6>
                <Input
                  name="dealPrice"
                  value={formData.dealPrice.dealPrice}
                  onChange={(e) => handleNestedChange(e, "dealPrice")}
                  style={{
                    width: "100px",
                    padding: "6px 10px",
                    margin: "10px",
                  }}
                />
              </span>
            </div>
            <br />
            <p>
              Off{" "}
              <Switch
                {...label}
                size="small"
                checked={formData.isActive}
                onChange={handleToggle}
              />{" "}
              On
            </p>
          </div>
          <div className="button-settings pt-2">
            <div>
              <Button onClick={handleSubmit} className="btn-1">
                Save
              </Button>
            </div>
            <div>
              <Button className="btn-2">Cancel</Button>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default AddnewDeal;
