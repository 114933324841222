import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { IoEyeOff } from "react-icons/io5";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../Api/Config";

const Resetpassword = () => {
  const [newPassword, setNewPassword] = useState(""); 
  const [confirmPassword, setConfirmPassword] = useState(""); 
const navigate = useNavigate();
  const getAuthToken = () => {
    return localStorage.getItem("dashtoken");
  };

  const getHeaders = () => {
    const token = getAuthToken();
    return {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };
  };

  const handleResetPassword = async () => {
    if (!newPassword || !confirmPassword) {
      message.error("Please fill in all fields.");
      return;
    }
    
    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match.");
      return;
    }
  
    try {
      const response = await axios.patch(
        `${API_URL}/api/user/auth/resetPassword`,
        { newPassword }
      );
  
      if (response.status === 200) {
        message.success("Password reset successfully.");
        navigate('/');
       
      } else {
        message.error("Failed to reset password. Please try again.");
      }
    } catch (error) {
      // Enhanced error handling
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error("Response data:", error.response.data);
          message.error(`Error: ${error.response.data.message || "An error occurred."}`);
        } else if (error.request) {
          // The request was made but no response was received
          message.error("No response received from server.");
        } else {
          // Something happened in setting up the request
          message.error("Error in setting up request: " + error.message);
        }
      } else {
        message.error("An unexpected error occurred.");
        console.error("Unexpected error:", error);
      }
    }
  };
  

  return (
    <>
      <section>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="login-1">
                <div className="login-logo">
                  <img
                    src="/assets/logo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="login-form">
                <div>
                  <h2 className="head-txt text-center">Reset Password</h2>
                  <h6 className="login-txt-1">New Password</h6>
                  <p className="login-txt-2">
                    Enter the new password & confirm password to reset
                  </p>

                  <div className="login-div">
                    <label>New Password</label>
                    <br />
                    <Input 
                      size="large" 
                      suffix={<IoEyeOff />} 
                      value={newPassword} // Bind input value to state
                      onChange={(e) => setNewPassword(e.target.value)} // Update state on change
                      type="password" // Set type to password for security
                    />
                  </div>

                  <div className="login-div mt-2">
                    <label>Confirm Password</label>
                    <br />
                    <Input 
                      size="large" 
                      suffix={<IoEyeOff />} 
                      value={confirmPassword} // Bind input value to state
                      onChange={(e) => setConfirmPassword(e.target.value)} // Update state on change
                      type="password" // Set type to password for security
                    />
                  </div>

                  <div className="d-flex justify-content-center mt-5">
                    <Button className="btn-1" onClick={handleResetPassword}>Reset</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Resetpassword;
