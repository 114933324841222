import React, { useState } from "react";
import { Input, message } from "antd";
import { Button, Container } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { API_URL } from "../Api/Config";
import { useNavigate } from "react-router-dom";
import { IoCaretBackOutline } from "react-icons/io5";

// Defining the keys of the 'access' object as a union type
type AccessKey =
  | "home"
  | "banner"
  | "products"
  | "orders"
  | "banner"|"transaction"
  | "setting";

interface FormData {
  name: string;
  position: string;
  email: string;
  password: string;
  access: {
    [key in AccessKey]: boolean;
  };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddSubadmin: React.FC = () => {
  // Initialize form data with empty values
  const [formData, setFormData] = useState<FormData>({
    name: "",
    position: "",
    email: "",
    password: "",
    access: {
      home: false,
      banner: false,
      products: false,
      orders: false,
      transaction:false,
      setting: false,
    },
  });

  const navigate = useNavigate();

  // Handle text input field changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle checkbox changes for access rights
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      access: {
        ...formData.access,
        [name as AccessKey]: checked, // TypeScript knows 'name' is a valid AccessKey here
      },
    });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      // Sending form data via POST request
      const response = await axios.post(
        `${API_URL}/api/subadmin/auth/create`,
        formData,{
          headers:{
             Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          }
        }
      );
      message.success("Subadmin created successfully!");
      navigate("/settings/subadmin");
      // Optionally, redirect or show a success message
    } catch (error) {
      console.error("Error saving subadmin:", error);
    }
  };

  // Defining the access keys array explicitly typed as AccessKey[]
  const accessKeys: AccessKey[] = [
    "home",
    "products",
    "orders",
    "banner",
    "setting",
    "transaction"
  ];

  return (
    <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
      <Container>
        <div>
          <div className="head-txt d-flex">
            <a
              onClick={() => window.history.back()}
              className="icons-div"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#000",
              }}
            >
              <IoCaretBackOutline className="mb-1" />
              <span>Add SubAdmin</span>
            </a>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="mt-4">
                  <div>
                    <label className="brand-label">Name*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-lg-3">
                    <label className="brand-label">Position*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="position"
                      value={formData.position}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-lg-3">
                    <label className="brand-label">Email Id*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-lg-3">
                    <label className="brand-label">Password*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="password"
                      type="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="p-3">
                  <p className="txt-1">Access*</p>

                  <div className="admin-checkbox">
                    {accessKeys.map((accessKey: AccessKey) => (
                      <div className="d-flex gap-2" key={accessKey}>
                        <div>
                          <Checkbox
                            {...label}
                            size="small"
                            name={accessKey}
                            checked={formData.access[accessKey]}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="checkbox-admin">
                          <label className="categories-label">
                            {accessKey.charAt(0).toUpperCase() +
                              accessKey.slice(1)}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="button-settings">
                <div>
                  <Button className="btn-2" type="button">
                    Cancel
                  </Button>
                </div>

                <div>
                  <Button
                    className="btn-1"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default AddSubadmin;
