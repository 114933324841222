import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Button, Input, message } from "antd";
import { Switch } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import type { RcFile } from "antd/es/upload/interface";
import { API_URL } from "../../Api/Config";
import { IoCaretBackOutline } from "react-icons/io5";

const AddnewBanner = () => {
  const [position, setPosition] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState<RcFile | null>(null);
  const [active, setActive] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const { bannerid } = useParams();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file as RcFile);
        setImagePreview(reader.result as string);
        message.success(`${file.name} selected successfully`);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/banner/herobanners/${bannerid}`,{
          headers:{
             Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          }
        }
      );
      console.log(response.data);
      setImage(response.data.banner.image);
      setImagePreview(response.data.banner.image);
      setPosition(response.data.banner.position);
      setLink(response.data.banner.link);
      setActive(response.data.banner.actionButton);
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  const handleSubmit = async () => {
    if (!link || !image) {
      message.error("Please fill in all fields and upload an image.");
      return;
    }
    const formData = new FormData();
    formData.append("position", "1");
    formData.append("link", link);
    formData.append("actionButton", JSON.stringify(active));
    formData.append("image", image);
    try {
      if (!bannerid) {
        await axios.post(`${API_URL}/api/banner/heroBanners`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
             Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          },
        });
      }
      if (bannerid) {
        await axios.patch(
          `${API_URL}/api/banner/heroBanners/${bannerid}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
            },
          }
        );
      }
      message.success("Banner added successfully!");
      navigate("/banners/home-banner");
    } catch (error) {
      console.error("Upload error:", error);
      message.error("Failed to add banner!");
    }
  };

  return (
    <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
      <Container>
        <div className="d-flex justify-content-between mb-3">
          <div className="head-txt d-flex">
            <a
              onClick={() => window.history.back()}
              className="icons-div"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#000",
              }}
            >
              <IoCaretBackOutline className="mb-1" />
              <span>Add Banner</span>
            </a>
          </div>
        </div>
        <div className="addbanner">
          <label className="addbrand-label mt-3">
            Banner <span>(Upload in 1440*500 px)</span>
          </label>
          <br />
          <input
            type="file"
            accept="image/*" // Accept only image files
            onChange={handleImageChange}
            style={{ marginBottom: "10px" }}
          />
          {imagePreview && (
            <div style={{ marginTop: "10px" }}>
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "150px",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
          <br />
          <label className="addbrand-label mt-3">
            Link <span>(Destination)</span>
          </label>
          <br />
          <Input
            value={link}
            onChange={(e) => setLink(e.target.value)}
            style={{ width: "445px" }}
          />
          <br />
          <label className="addbrand-label mt-3">Active</label>
          <br />
          <p>
            Off{" "}
            <Switch
              checked={active}
              onChange={() => setActive(!active)}
              size="small"
            />{" "}
            On
          </p>
        </div>
        <div className="button-settings pt-2">
          <Button className="btn-1" onClick={handleSubmit}>
            Save
          </Button>
          <Button className="btn-2">Cancel</Button>
        </div>
      </Container>
    </section>
  );
};
export default AddnewBanner;
