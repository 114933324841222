import React, { useEffect, useState } from "react";
import { message, Input, Button as ButtonA, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import colorName from "color-name";
import { RcFile, UploadFile} from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import styled from "@emotion/styled";
import axios from "axios";
import { API_URL } from "../../Api/Config";
import { useNavigate, useParams } from "react-router-dom";

interface Variant {
  ramSize: string;
  storage: string;
  price: number;
  dealPrice: string;
  colorCode?: string;
  colorName?: string;
  images?: UploadFile[];
}

const hexToRgb = (hex: any) => {
  const bigint = parseInt(hex.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
};

const getColorNameFromRgb = (rgb: any) => {
  const closestColor = Object.entries(colorName).find(([_, value]) => {
    return value[0] === rgb[0] && value[1] === rgb[1] && value[2] === rgb[2];
  });

  if (closestColor) {
    return closestColor[0];
  } else {
    let closestName = "Unknown";
    let closestDistance = Infinity;

    Object.entries(colorName).forEach(([name, value]) => {
      const distance = Math.sqrt(
        Math.pow(value[0] - rgb[0], 2) +
          Math.pow(value[1] - rgb[1], 2) +
          Math.pow(value[2] - rgb[2], 2)
      );

      if (distance < closestDistance) {
        closestDistance = distance;
        closestName = name;
      }
    });

    return closestName;
  }
};

const StyledDragger = styled(Dragger)`
  .ant-upload-drag-icon {
    font-size: 1.5rem;
    color: #0d354f;
    margin-bottom: 10px;
  }
  .ant-upload-drag-text {
    color: #333;
    font-size: 1rem;
  }
  :where(.css-dev-only-do-not-override-5wsri9).ant-upload-wrapper
    .ant-upload-drag {
    width: 60px;
    height: 50px;
  }
`;

interface EditvariantProps {
  onProductUpdate: (product: any) => void;
}

const EditPhonevariant: React.FC<EditvariantProps> = ({ onProductUpdate }) => {
  const [variant, setVariant] = useState({
    variants: [
      {
        ramSize: "",
        storage: "",
        price: 0,
        dealPrice: "",
        colorCode: "",
        colorName: "",
        images: [] as UploadFile[],
      },
    ],
  });
  const { productid } = useParams<{ productid: string }>(); 
  const navigate = useNavigate()


  const handleColorChange = (event: any, index: number) => {
    const color = event.target.value;
    const rgb = hexToRgb(color);
    const colorName = getColorNameFromRgb(rgb);

    // Update colorCode and colorName for the selected variant
    const updatedVariants = [...variant.variants];
    updatedVariants[index].colorCode = color;
    updatedVariants[index].colorName = colorName;

    setVariant((prevProduct) => ({
      ...prevProduct,
      variants: updatedVariants,
    }));
  };

  const handleInputChange = (
    index: number,
    field: string,
    value: string
  ): void => {
    const updatedVariants = [...variant.variants];
    if (field === "variants") {
      const [ramSize = "", storage = ""] = value.split(" / ");
      updatedVariants[index].ramSize = ramSize;
      updatedVariants[index].storage = storage;
    } else {
      updatedVariants[index] = { ...updatedVariants[index], [field]: value };
    }
    setVariant((prevProduct) => ({
      ...prevProduct,
      variants: updatedVariants,
    }));
  };

  const fectProductData =async () => {
    if (!productid) {
      message.error("product ID is missing, redirecting to prodect list...");
      navigate("/products");
      return;
    }
    try {
      const response = await axios.get(`${API_URL}/api/products/getById/${productid}`,{
        headers:{
           Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
        }
      });
      setVariant(response.data);
      
    } catch (error) {
      console.error("Error fetching Product:", error);
      message.error("Failed to fetch products details.");
    }
  };

  useEffect(() => {
    fectProductData();
  }, [productid, navigate]);

  const handleAddProductVariant = () => {
    setVariant((prevProduct) => ({
      ...prevProduct,
      variants: [
        ...prevProduct.variants,
        {
          ramSize: "",
          storage: "",
          price: 0,
          dealPrice: "",
          colorCode: "",
          colorName: "",
          images: [],
        },
      ],
    }));
  };

  const handleCallBack = () => {
    const updatedProduct = {
      ...variant,
      variants: [...variant.variants],
    };
    console.log(updatedProduct.variants);
    
    setVariant(updatedProduct);
    onProductUpdate(updatedProduct.variants);
  };

  // const props = {
  //   name: "file",
  //   multiple: true,
  //   beforeUpload: (file: RcFile) => {
  //     return false;
  //   },
  //   onChange: (info: any, index: number) => {
  //     const newFileList = info.fileList;
  //     handleFileChange(index, newFileList);
  //   },
  // };
  const convertToBase64 = (file: RcFile): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: (file: RcFile) => {
      return false;
    },
    onChange: async (info: any, index: number) => {
      const newFileList = info.fileList;
      const base64Files = await Promise.all(
        newFileList.map(async (file: UploadFile) => {
          const base64 = await convertToBase64(file.originFileObj as RcFile);
          return {
            ...file,
            base64, // Store the base64 string in the file object
          };
        })
      );
      handleFileChange(index, base64Files);
    },
  };

  const handleFileChange = (index: number, fileList: UploadFile[]) => {
    const updatedVariants = [...variant.variants];
    updatedVariants[index].images = fileList;
    setVariant((prevProduct) => ({
      ...prevProduct,
      variants: updatedVariants,
    }));
  };

 

  return (
    <div className="product-contain-box">
      {variant.variants.map((variant, index) => (
        <div key={index} className="product-box">
          {/* Variant RAM and Storage */}
          <div>
            {index === 0 && <label className="addbrand-label">Variant</label>}
            <br />
            <Input
              className="product-input"
              value={variant.ramSize}
              placeholder="RamSize"
              onChange={(e) =>
                handleInputChange(index, "ramSize", e.target.value)
              }
            />
            <Input
              className="product-input"
              placeholder="RomSize"
              value={variant.storage}
              onChange={(e) =>
                handleInputChange(index, "storage", e.target.value)
              }
            />
          </div>

          {/* M.R.P */}
          <div>
            {index === 0 && <label className="addbrand-label">M.R.P</label>}
            <br />
            <div className="mrp-box">
              <Input
                className="product-input"
                value={variant.price}
                onChange={(e) =>
                  handleInputChange(index, "price", e.target.value)
                }
              />
            </div>
          </div>

          {/* dealPrice */}
          <div>
            {index === 0 && (
              <label className="addbrand-label">DealPrice</label>
            )}
            <br />
            <div className="dealPrice-box">
              <Input
                className="product-input"
                value={variant.dealPrice}
                onChange={(e) =>
                  handleInputChange(index, "dealPrice", e.target.value)
                }
              />
            </div>
          </div>

          {/* Color */}
          <div>
            {index === 0 && (
              <label
                className="addbrand-label"
                style={{ paddingBottom: "15px" }}
              >
                Color
              </label>
            )}
            <div>
              <div
                onClick={() => {
                  const colorInput = document.getElementById(
                    `hiddenColorInput-${index}`
                  );
                  if (colorInput) colorInput.click();
                }}
                style={{
                  backgroundColor: variant.colorCode,
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  border:"1px solid #000"
                }}
              />
              <Input
                type="color"
                id={`hiddenColorInput-${index}`}
                value={variant.colorCode}
                onChange={(e) => handleColorChange(e, index)}
                style={{ display: "none" }}
              />
            </div>
          </div>

          {/* Color Name */}
          <div>
            {index === 0 && (
              <>
                <label className="addbrand-label">Name</label>
                <br />
              </>
            )}
            <Input value={variant.colorName} readOnly className="product-input" />
          </div>

          {/* Upload Images */}
          <div>
            <StyledDragger
              {...props}
              onChange={(info) => props.onChange(info, index)}
              fileList={variant.images}
              className="dragger-box"
            >
              <UploadOutlined />
            </StyledDragger>
          </div>
        </div>
      ))}

      <div className="text-center">
        <ButtonA className="add-btn-box" onClick={handleAddProductVariant}>
          +
        </ButtonA>
      </div>
      <div className="text-center pt-3">
        <Button onClick={handleCallBack} className="btn-save">
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditPhonevariant ;
