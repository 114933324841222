import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import { Switch } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import type { RcFile } from "antd/es/upload/interface";
import { API_URL } from "../../Api/Config";
import { IoCaretBackOutline } from "react-icons/io5";

const AddOfferBanner = () => {
  const [position, setPosition] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState<RcFile | null>(null);
  const [active, setActive] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const { bannerType, bannerId } = useParams(); 
  const navigate = useNavigate();

  useEffect(() => {
    if (bannerId) {
      setIsEditMode(true);
      fetchBannerDetails();
    }
  }, [bannerId]);

  const fetchBannerDetails = async () => {
    try {
      const apiUrl = `${API_URL}/api/banner/${bannerType}/${bannerId}`;
      const { data } = await axios.get(apiUrl,{
        headers:{
          Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
        }
      });

      setPosition(data.banner.position);
      setLink(data.banner.link);
      setActive(data.banner.actionButton);
      setCouponCode(data.banner.couponCode || "");
      setDiscount(data.banner.discount || "");
      if (data.banner.image) {
        setImagePreview(data.banner.image);
      }
    } catch (error) {
      console.error("Error fetching banner details:", error);
      message.error("Failed to load banner details.");
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file as RcFile);
        setImagePreview(reader.result as string);
        message.success(`${file.name} selected successfully`);
      };
      reader.readAsDataURL(file);
    }
  };

  const getApiUrl = () => {
    
    switch (bannerType) {
      case "offerBanners":
        return `${API_URL}/api/banner/offerBanners`;
      case "festivalBanners":
        return `${API_URL}/api/banner/festivalBanners`;
      case "bestOfElectronicsBanners":
        return `${API_URL}/api/banner/bestOfElectronicsBanners`;
      case "hotDealsSmartPhoneBanners":
        return `${API_URL}/api/banner/hotDealsSmartPhoneBanners`;
      case "limitedTimeDealsBanners":
        return `${API_URL}/api/banner/limitedTimeDealsBanners`;
      default:
        message.error("Invalid banner type.");
        return "";
    }
  };

  const handleSubmit = async () => {
    if (!position) {
      message.error("Please fill in all required fields.");
      return;
    }
  
    // Adjust link value based on couponCode and discount
    const finalLink = couponCode || discount ? null : link;
  
   


    
    const formData = new FormData();
    formData.append("position", "1");
    formData.append("link", finalLink || ""); 
    formData.append("actionButton", JSON.stringify(active));
    if (image) formData.append("image", image);
    formData.append("couponCode", couponCode);
    formData.append("discount", discount);
  
    const apiUrl = getApiUrl();
    if (!apiUrl) return;
    try {
      if (isEditMode) {
        // Edit request
        await axios.patch(`${apiUrl}/${bannerId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          },
        });
        message.success("Banner updated successfully!");
      } else {
        // Add request
        await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          },
        });
        message.success("Banner added successfully!");
      }
      navigateAfterBannerAdded(bannerType);
    } catch (error) {
      console.error("Upload error:", error);
      message.error("Failed to save banner. Please try again.");
    }
  };
  
  const navigateAfterBannerAdded = (bannerType: string | undefined) => {
    switch (bannerType) {
      case "offerBanners":
        navigate("/home-banner");
        break;
      case "festivalBanners":
        navigate("/home-banner");
        break;
      case "bestOfElectronicsBanners":
        navigate("/home-offer");
        break;
      case "hotDealsSmartPhoneBanners":
        navigate("/home-offer");
        break;
      case "limitedTimeDealsBanners":
        navigate("/home-offer");
        break;
      default:
        message.error("Invalid banner type.");
        break;
    }
  };

  const getBannerSizeDescription = () => {
    switch (bannerType) {
      case "offer":
        return "(Upload in 1440*300 px)";
      case "festival":
        return "(Upload in 1400*300 px)";
      case "bestOfElectronics":
        return "(Upload in 560*300 px)";
      case "hotDealsSmartPhone":
        return "(Upload in 360*300 px)";
      case "limitedTimeDeals":
        return "(Upload in 560*300 px)";
      default:
        return "(Upload in appropriate dimensions)";
    }
  };


  return (
    <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
      <Container>
        <div className="d-flex justify-content-between mb-3">
          <div className="head-txt d-flex">
          <a
          onClick={() => window.history.back()}
          className="icons-div"
          style={{
            textDecoration: "none",
            cursor: "pointer",
            color: "#000",
          }}
        >
          <IoCaretBackOutline className="mb-1" />
          <span>Add Banner</span>
        </a>
          </div>
        </div>
        <div className="addbanner">
         
          <label className="addbrand-label mt-3">
            Banner <span>{getBannerSizeDescription()}</span>
          </label>
          <br />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ marginBottom: "10px" }}
          />
          {imagePreview && (
            <div style={{ marginTop: "10px" }}>
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "150px",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
          <br />
          <label className="addbrand-label mt-3">
            Link <span>(Destination)</span>
          </label>
          <br />
          {(couponCode || discount) ? (
            <p style={{ color: "#888" }}>Link field is disabled when Coupon Code or Discount is entered.</p>
          ) : (
            <Input
              value={link}
              style={{width:"445px"}}
              onChange={(e) => setLink(e.target.value)}
              // placeholder="Enter destination link"
            />
          )}
          <br />


          <label className="addbrand-label mt-3">Coupon Code</label>
          <br />
          <Input value={couponCode} onChange={(e) => setCouponCode(e.target.value)} style={{width:"445px"}}/>
          <br />

          <label className="addbrand-label mt-3">Discount</label>
          <br />
          <Input type="number" value={discount} onChange={(e) => setDiscount(e.target.value)} style={{width:"445px"}}/>
          <br />
          <p>
            Off <Switch checked={active} onChange={() => setActive(!active)} size="small" /> On
          </p>
        </div>
        <div className="button-settings pt-2">
          <Button className="btn-1" onClick={handleSubmit}>Save</Button>
          <Button className="btn-2">Cancel</Button>
        </div>
      </Container>
    </section>
  );
};
export default AddOfferBanner;
