import  { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../Home/Home";
import Analytics from "../Home/Analytics";
import Brands from "../Brands/Brands";
import AddBrands from "../Brands/Addbrand";
import Order from "../Order/Order";
import Addproducts from "../Products/Addproducts/AddProducts";
import Productlist from "../Products/Productlist/Productlist";
import Transaction from "../Transaction/Transaction";
import Domain from "../Settings/Domain";
import Profile from "../Settings/Profile";
import Subadmin from "../Settings/Subadmin";
import Adddomain from "../Settings/Adddomain";
import AddSubadmin from "../Settings/AddSubadmin";
import SetEstimateDelivery from "../Settings/SetEstimateDelivery";
import HomeBannerMain from "../Banners/HomeBanner/HomeBannerMain";
import HomeOfferMain from "../Banners/HomeOffer/HomeOfferMain";
import OfferBannerMain from "../Banners/OfferBanner/OfferBannerMain";
import AddnewBanner from "../Banners/HomeBanner/AddnewBanner";
import AddNewDelivery from "../Settings/AddNewDelivery";
// import AddOffersBanner from "../Banners/OfferBanner/Add-Offer-Banner";
import AddnewDeal from "../Banners/HomeOffer/AddnewDeal";
import Login from "../Login/Login";
import SubAdmin from "../Login/subadmin";
import SideBar from "../Home/Sidebar";
import Header from "../Home/Header";
import Forgetpassword from "../Login/Forget-password";
import OTP from "../Login/Otp-Number";
import Resetpassword from "../Login/Reset-password";
import Bestofelectronics from "../Banners/HomeOffer/BestofElectronics";
import Limitedtimedeals from "../Banners/HomeOffer/LimitedTimeDeals";
import Hotdealofsmartphone from "../Banners/HomeOffer/HotDealsofSmartphones";
import DealofDay from "../Banners/HomeOffer/Dealofday";
import Offerbanner from "../Banners/HomeBanner/Offerbanner";
import FestivalBanner from "../Banners/HomeBanner/FestivalBanners";
import AddOfferBanner from "../Banners/OfferBanner/Add-Offer-Banner";
import DomainEdit from "../Settings/Edit-Domain";
import OrderDetails from "../Order/OrderDetails";
import EditDelivery from "../Settings/EditDelivery";
import EditProducts from "../Products/Addproducts/EditProducts";
import EditSubadmin from "../Settings/Edit-subadmin";
import EditDeal from "../Banners/HomeOffer/edit-deal";
import axios from "axios";
import { API_URL } from "../Api/Config";


const Routing = () => {

  const [isUser, setIsUser] = useState(false);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const checkToken = () => {
      const adminToken = localStorage.getItem("dashtoken");
      const subAdminToken = localStorage.getItem("subadmintoken");
      if (adminToken) {
        setIsUser(true);
        setRole("admin");
      } else if (subAdminToken) {
        setIsUser(true);
        setRole("subadmin");
      } else {
        setIsUser(false);
        setRole("");
        localStorage.setItem("role","")

      }
      setLoading(false);
    };
    checkToken();
    const handleStorageChange = () => {
      checkToken();
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);





  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <BrowserRouter>
      {!isUser ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/subadminlogin" element={<SubAdmin />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/forgotpassword" element={<Forgetpassword />} />
          <Route path="/otp-verify" element={<OTP />} />
          <Route path="/resetpassword" element={<Resetpassword />} />
        </Routes>
      ) : (
        <>
          <section className="sidebar">
            <SideBar />
          </section>
          <section className="home">
            <Header />
          </section>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/products" element={<Productlist />} />
            <Route path="/addbrands" element={<AddBrands />} />
            <Route path="/order" element={<Order />} />
            <Route path="/product/productlist" element={<Productlist />} />
            <Route path="/product/addproducts" element={<Addproducts />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/settings/domain" element={<Domain />} />
            <Route path="/settings" element={<Profile />} />
            <Route path="/settings/profile" element={<Profile />} />
            <Route path="/settings/set-estimate-delivery" element={<SetEstimateDelivery />} />
            <Route path="/add-new-delivery" element={<AddNewDelivery />} />
            <Route path="/settings/subadmin" element={<Subadmin />} />
            <Route path="/add-domain" element={<Adddomain />} />
            <Route path="/edit-domain/:domainId" element={<DomainEdit />} />
            <Route path="/add-subadmin" element={<AddSubadmin />} />
            <Route path="/banners" element={<HomeBannerMain />} />
            <Route path="/banners/home-banner" element={<HomeBannerMain />} />
            <Route path="/banners/home-offer" element={<HomeOfferMain />} />
            <Route path="/banners/offer-banner" element={<OfferBannerMain />} />
            <Route path="/forgotpassword" element={<Forgetpassword />} />
            <Route path="/otp-verify" element={<OTP />} />
            <Route path="/add-deal-product" element={<AddnewDeal />} />
            <Route path="/best-of-electronics" element={<Bestofelectronics />} />
            <Route path="/limited-time-deal" element={<Limitedtimedeals />} />
            <Route path="/hot-deal-of-smartphone" element={<Hotdealofsmartphone />} />
            <Route path="/deal-of-day" element={<DealofDay />} />
            <Route path="/Offerbannner" element={<Offerbanner />} />
            <Route path="/festival-banner" element={<FestivalBanner />} />
            <Route path="/add-banner" element={<AddnewBanner />} />
            <Route path="/add-offers-banner/:bannerType" element={<AddOfferBanner />} />
            <Route path="/add-dealof-day" element={<AddnewDeal />} />
            <Route path="/order-details/:orderId" element={<OrderDetails />} />
            <Route path="/edit-delivery/:id" element={<EditDelivery />} />
            <Route path="/editproducts/:productid" element={<EditProducts />} />
            <Route path="/editsubadmin/:subadminId" element={<EditSubadmin />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/add-banner/:bannerid" element={<AddnewBanner />} />
            <Route path="/edit-banner/:bannerType/:bannerId" element={<AddOfferBanner />} />
            <Route path="/edit-deal-product/:dealId" element={<EditDeal />} />
          </Routes>
        </>
      )}
    </BrowserRouter>
  );
};

export default Routing;
