import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Input, message } from "antd";
import axios from "axios";
import { API_URL } from "../Api/Config";
import { CameraOutlined } from "@ant-design/icons";

type ProfileData = {
  firstName: string;
  lastName: string;
  email: string;
  position: string;
  imageUrl: File | null;
};

const Profile = () => {
  const [profileData, setProfileData] = useState<ProfileData>({
    firstName: "",
    lastName: "",
    email: "",
    position: "",
    imageUrl: null,
  });
  const [imageFile, setImageFile] = useState<File | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);

      // Display image preview
      const reader = new FileReader();
      reader.onload = () => {
        setProfileData((prevData) => ({
          ...prevData,
          imageUrl: file,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (
      !profileData.firstName ||
      !profileData.lastName ||
      !profileData.email ||
      !profileData.position
    ) {
      message.error("Please fill in all required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("firstName", profileData.firstName);
    formData.append("lastName", profileData.lastName);
    formData.append("email", profileData.email);
    formData.append("position", profileData.position);
    if (profileData.imageUrl) {
      formData.append("image", profileData.imageUrl);
    }

    try {
      const response = await axios.patch(
        `${API_URL}/api/user/auth/admin/updateadmin`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
             Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          },
        }
      );

      message.success("Profile details saved successfully.");
      window.location.reload()
      await fetchUserData();
    } catch (error) {
      message.error("An error occurred while saving profile details.");
      console.error("Error:", error);
    }
  };

  const handleSaveSubAdmin = async () => {
    if (
      !profileData.firstName ||
      !profileData.lastName ||
      !profileData.email ||
      !profileData.position
    ) {
      message.error("Please fill in all required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("firstName", profileData.firstName);
    formData.append("lastName", profileData.lastName);
    formData.append("email", profileData.email);
    formData.append("position", profileData.position);
    if (profileData.imageUrl) {
      formData.append("image", profileData.imageUrl);
    }

    try {
      const response = await axios.patch(
        `${API_URL}/api/subadmin/update`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          },
        }
      );

      message.success("Profile details saved successfully.");
      window.location.reload()
      await fetchUserData();
    } catch (error) {
      message.error("An error occurred while saving profile details.");
      console.error("Error:", error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/user/getMe`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("dashtoken")}`,
        },
      });
      setProfileData({
        ...profileData,
        firstName: response.data.user.firstName,
        lastName: response.data.user.lastName,
        email: response.data.user.email,
        position: response.data.user.position,
        imageUrl: response.data.user.profileImage,
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const roles = localStorage.getItem("role");

  return (
    <section
      className="top-gap section-main pb-5"
      style={{
        marginLeft: "18%",
        minHeight: "100vh",
        paddingBottom: "0",
      }}
    >
      <Container>
        <div>
          <h2 className="head-txt">Profile</h2>
        </div>
        <div>
          <div className="upload-circle">
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f0f0f0",
                position: "relative",
                cursor: "pointer",
              }}
            >
              {profileData.imageUrl ? (
                <img
                  src={`${API_URL}/public/images/${profileData.imageUrl}`}
                  alt="avatar"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <CameraOutlined style={{ fontSize: "1.5rem" }} />
                  <span className="text-center">
                    Upload
                    <br />
                    Photo
                  </span>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{
                  opacity: 0,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="p-lg-3">
                {" "}
                {/* Reduced padding */}
                <div>
                  <label className="addbrand-label">First Name*</label>
                  <br />
                  <Input
                    className="brand-input"
                    name="firstName"
                    value={profileData.firstName}
                    onChange={handleInputChange}
                    style={{height:"40px"}}
                  />
                </div>
                <div className="mt-3">
                  <label className="addbrand-label">Email ID*</label>
                  <br />
                  <Input
                    name="email"
                    value={profileData.email}
                    onChange={handleInputChange}
                    className="brand-input"
                    disabled
                    style={{height:"40px"}}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="p-lg-3">
                <div>
                  <label className="addbrand-label">Last Name*</label>
                  <br />
                  <Input
                    className="brand-input"
                    name="lastName"
                    value={profileData.lastName}
                    onChange={handleInputChange}
                    style={{height:"40px"}}
                  />
                </div>
                <div className="mt-3">
                  <label className="addbrand-label">Position*</label>
                  <br />
                  <Input
                    className="brand-input"
                    name="position"
                    value={profileData.position}
                    onChange={handleInputChange}
                    disabled
                    style={{height:"40px"}}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="button-settings">
            <div>
              <Button className="btn-2">Cancel</Button>
            </div>
            <div>
              <Button
                className="btn-1"
                onClick={roles === "admin" ? handleSave : handleSaveSubAdmin}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Profile;
