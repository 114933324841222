import React from "react";
import { Container } from "react-bootstrap";
import Hotdealofsmartphone from "./HotDealsofSmartphones";
import Bestofelectronics from "./BestofElectronics";
import Limitedtimedeals from "./LimitedTimeDeals";
import DealofDay from "./Dealofday";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const HomeOfferMain = () => {
  return (
    <>
      <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
        <Container>
          <div>
            <h2 className="head-txt">Home Offers</h2>
          </div>

          <Tabs
            defaultActiveKey="hot-deals-of-smart-phones"
            id="uncontrolled-tab-example"
            className="mb-3 custom-tabs"
          >
            <Tab
              eventKey="hot-deals-of-smart-phones"
              title="Hot Deals of Smart Phones"
            >
              <Hotdealofsmartphone />
            </Tab>
            <Tab eventKey="best-of-electronics" title="Best of Electronics">
              <Bestofelectronics />
            </Tab>
            <Tab eventKey="limited-time-deals" title="Limited Time Deals">
              <Limitedtimedeals />
            </Tab>
            {/* <Tab eventKey="deal-of-the-day" title="Deal of the Day">
              <DealofDay />
            </Tab> */}
          </Tabs>
        </Container>
      </section>
    </>
  );
};

export default HomeOfferMain;
