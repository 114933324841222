import { Container } from "react-bootstrap";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Neworders from "./Neworders";
import Shippedorder from "./Shippedorder";
import Completedorder from "./Completedorder";
import Cancelledorder from "./Cancelledorder";
import Replacementorder from "./Replacementorder";
import OutforDelivery from "./OutforDelivery";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Order = () => {
  return (
    <>
      <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
        <Container>
          <div>
            <h2 className="head-txt pb-2">Orders</h2>

            <Tabs
              defaultActiveKey="new-orders"
              id="uncontrolled-tab-example"
              className="mb-3 custom-tabs"
            >
              <Tab eventKey="new-orders" title="New Orders">
                <Neworders />
              </Tab>
              <Tab eventKey="shipped-orders" title="Shipped Orders">
                <Shippedorder />
              </Tab>
              <Tab
                eventKey="out-for-delivery-orders"
                title="Out For Delivery Orders"
              >
                <OutforDelivery />
              </Tab>
              <Tab eventKey="completed-orders" title="Completed Orders">
                <Completedorder />
              </Tab>
              <Tab eventKey="canceled-orders" title="Canceled Orders">
                <Cancelledorder />
              </Tab>
              <Tab eventKey="replacement-orders" title="Replacement Orders">
                <Replacementorder />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Order;
