import { Button, message } from "antd";
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { FaCaretLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { MdOutlineFileDownload } from "react-icons/md";
import axios from "axios";
import { API_URL } from "../Api/Config";

const OrderDetails = () => {

  const { orderId } = useParams<{ orderId: string }>();
  
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!orderId) {
      message.error("Order ID is missing!");
      return;
    }
  
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/orders/${orderId}`,{
          headers:{
             Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          }
        });
        console.log("Order Details:", response.data.order);  
        setOrderDetails(response.data.order);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order details:", error);
        message.error("Failed to fetch order details.");
        setLoading(false);
      }
    };
  
    fetchOrderDetails();
  }, [orderId]);
  

  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!orderDetails) {
    return <div>No order details available.</div>;
  }
  


  const {
    user,
    products,
    shippingAddress,
    GSTInvoice,
    totalAmount,
    finalAmount,
    paymentMethod,
    paymentStatus,
    totalDiscountedAmount,
    status,
    deliveryType,
    orderDate,
    deliveryDate,
    isCancelled,
    cancellationReason,
    refundStatus,
    replacementstatus,
  } = orderDetails;

  return (
    <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
      <Container>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/order" className="head-span">
            <FaCaretLeft className="me-2 mb-1" />
            Order Details
          </Link>
          <Button className="btn-1">Track</Button>
        </div>

        {/* Order Info */}
        <div className="row pt-3">
          <div className="col-lg-6 col-md-6">
            <h4>Order Info</h4>
            <table className="tableorder">
              <tbody>
                <tr>
                  <td>User ID</td>
                  <td>{user}</td>
                </tr>
                <tr>
                  <td>Total Amount</td>
                  <td>₹ {totalAmount}</td>
                </tr>
                <tr>
                  <td>Final Amount</td>
                  <td>₹ {finalAmount}</td>
                </tr>
                <tr>
                  <td>Payment Method</td>
                  <td>{paymentMethod}</td>
                </tr>
                <tr>
                  <td>Payment Status</td>
                  <td>{paymentStatus}</td>
                </tr>
                <tr>
                  <td>Order Status</td>
                  <td>{status}</td>
                </tr>
                <tr>
                  <td>Delivery Type</td>
                  <td>{deliveryType}</td>
                </tr>
                <tr>
                  <td>Order Date</td>
                  <td>{new Date(orderDate).toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Delivery Date</td>
                  <td>{deliveryDate ? new Date(deliveryDate).toLocaleString() : "N/A"}</td>
                </tr>
                {isCancelled && (
                  <tr>
                    <td>Cancellation Reason</td>
                    <td>{cancellationReason}</td>
                  </tr>
                )}
                <tr>
                  <td>Refund Status</td>
                  <td>{refundStatus}</td>
                </tr>
                <tr>
                  <td>Replacement Status</td>
                  <td>{replacementstatus}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Shipping Address */}
          <div className="col-lg-6 col-md-6">
            <h4>Shipping Address</h4>
            <table className="tableorder">
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>{shippingAddress?.firstName || "N/A"}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{shippingAddress?.lastName || "N/A"}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{shippingAddress?.phoneNumber || "N/A"}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{shippingAddress?.email || "N/A"}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>
                    {`${shippingAddress?.houseNumberAndFloor || "N/A"}, ${shippingAddress?.buildingAndBlockNumber || "N/A"}, ${shippingAddress?.locality || "N/A"}`}
                  </td>
                </tr>
                <tr>
                  <td>Pincode</td>
                  <td>{shippingAddress?.pincode || "N/A"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Product Details */}
        <div className="py-4">
          <h4>Products</h4>
          <table className="tableorder2">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Product Name</th>
                <th>RAM</th>
                <th>Storage</th>
                <th>Color</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {products?.map((product: any, index: number) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{product.productName}</td>
                  <td>{product.ramSize || "N/A"}</td>
                  <td>{product.storage || "N/A"}</td>
                  <td>{product.colorName || "N/A"}</td>
                  <td>₹ {product.productPrice}</td>
                  <td>{product.discountInPercent}%</td>
                  <td>{product.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* GST Details */}
        <div className="py-4">
          <h4>GST Invoice</h4>
          <table className="tableorder">
            <tbody>
              <tr>
                <td>GST Enabled</td>
                <td>{GSTInvoice?.isGSTEnabled ? "Yes" : "No"}</td>
              </tr>
              {GSTInvoice?.isGSTEnabled && (
                <>
                  <tr>
                    <td>GST Number</td>
                    <td>{GSTInvoice?.gstDetails?.GSTNumber || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Business Name</td>
                    <td>{GSTInvoice?.gstDetails?.businessName || "N/A"}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </Container>
    </section>
  );
};

export default OrderDetails;
