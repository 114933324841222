import React from "react";
import { Container } from "react-bootstrap";
import HeroBanner from "./HeroBanner";
import Offerbanner from "./Offerbanner";
import Shopbybrand from "./FestivalBanners";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const HomeBannerMain = () => {
  return (
    <>
      <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
        <Container>
          <div>
            <h2 className="head-txt">Home Banners</h2>
          </div>

          <Tabs
            defaultActiveKey="hero-banner"
            id="uncontrolled-tab-example"
            className="mb-3 custom-tabs"
          >
            <Tab eventKey="hero-banner" title="Hero Banner">
              <HeroBanner />
            </Tab>

            <Tab eventKey="offer-banner" title="Offer Banner">
              <Offerbanner />
            </Tab>
            <Tab eventKey="festival-banner" title="Festival Banner">
              <Shopbybrand />
            </Tab>
          </Tabs>
        </Container>
      </section>
    </>
  );
};

export default HomeBannerMain;
