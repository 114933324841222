import React, { useState, useEffect } from "react";
import { Input, message } from "antd";
import { Button, Container } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../Api/Config";
import { IoCaretBackOutline } from "react-icons/io5";

type AccessKey = "home" | "products" | "orders" | "banner" | "transaction";

interface FormData {
  firstName: string;
  name: string;
  position: string;
  email: string;
  password?: string; // Password is optional for updates
  access: {
    [key in AccessKey]: boolean;
  };
}

const EditSubadmin: React.FC = () => {
  const { subadminId } = useParams<{ subadminId: string }>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    position: "",
    email: "",
    password: "",
    access: {
      home: false,
      products: false,
      orders: false,
      banner: false,
      transaction: false,
    },
    name: "",
  });

  const [loading, setLoading] = useState<boolean>(true);

  const fetchSubadmin = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/subadmin/${subadminId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dashtoken")}`,
          },
        }
      );
      setFormData(response.data.subadmin); 
      setLoading(false);
    } catch (error) {
      console.error("Error fetching subadmin details:", error);
      message.error("Failed to load subadmin details.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (subadminId) {
      fetchSubadmin();
    } else {
      setLoading(false); 
    }
  }, [subadminId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      access: {
        ...formData.access,
        [name as AccessKey]: checked,
      },
    });
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("dashtoken");
      if (subadminId) {
        // Update subadmin
        await axios.patch(`${API_URL}/api/subadmin/${subadminId}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        message.success("Subadmin updated successfully!");
      } else {
        // Create new subadmin
        await axios.post(`${API_URL}/api/subadmin/auth/create`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        message.success("Subadmin added successfully!");
      }
      navigate("/settings/subadmin");
    } catch (error) {
      console.error("Error saving subadmin:", error);
      message.error("Failed to save subadmin.");
    }
  };

  const accessKeys: AccessKey[] = [
    "home",
    "products",
    "orders",
    "banner",
    "transaction",
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
      <Container>
        <div>
          <div className="head-txt d-flex">
            <a
              onClick={() => window.history.back()}
              className="icons-div"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#000",
              }}
            >
              <IoCaretBackOutline className="mb-1" />
              <span>Edit SubAdmin</span>
            </a>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="mt-4">
                  <label className="brand-label">Name*</label>
                  <br />
                  <Input
                    className="brand-input"
                    name="name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                  <div className="mt-lg-3">
                    <label className="brand-label">Position*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="position"
                      value={formData.position}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-lg-3">
                    <label className="brand-label">Email Id*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  {!subadminId && ( // Show password only for adding a new subadmin
                    <div className="mt-lg-3">
                      <label className="brand-label">Password*</label>
                      <br />
                      <Input
                        className="brand-input"
                        name="password"
                        type="password"
                        value={formData.password || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="p-3">
                  <p className="txt-1">Access*</p>

                  <div className="admin-checkbox">
                    {accessKeys.map((accessKey: AccessKey) => (
                      <div className="d-flex gap-2" key={accessKey}>
                        <div>
                          <Checkbox
                            size="small"
                            name={accessKey}
                            checked={formData.access[accessKey]}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="checkbox-admin">
                          <label className="categories-label">
                            {accessKey.charAt(0).toUpperCase() +
                              accessKey.slice(1)}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="button-settings">
                <Button className="btn-2" onClick={() => navigate("/subadmin")}>
                  Cancel
                </Button>
                <Button className="btn-1" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default EditSubadmin;
