import { Button, Input, message } from "antd";
import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../Api/Config";
// import {Forgetpassword} from "./Forget-password";

interface AxiosErrorResponse {
  message?: string; 
  
}

const OTP = () => {
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);

const navigate = useNavigate();
  useEffect(() => {
    if (timer > 0 && isResendDisabled) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setIsResendDisabled(false); 
    }
  }, [timer, isResendDisabled]);

  const getAuthToken = () => {
    return localStorage.getItem("dashtoken");
  };
  const handleOtpInput = (value: string, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input when a digit is entered
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }

    // Move to the previous input when backspace is pressed on an empty input
    if (!value && index > 0) {
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
  };
  const handleVerifyOTP = async () => {
    const enteredOtp = otp.join(""); 
    if (enteredOtp.length !== 4) {
      message.error("Please enter a valid 4-digit OTP.");
      return;
    }
  
    try {
      const response = await axios.post(
        `${API_URL}/api/user/auth/otpValidation`,
        { otp: enteredOtp },
        { headers: { 
            "Content-Type": "application/json", 
            // Authorization: `Bearer ${getAuthToken()}` // Include token if required
          } 
        }
      );
  
      if (response.status === 200) {
        message.success("OTP verified successfully.");
        // Handle successful verification
      } else {
        message.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      // Use type assertion to treat error as AxiosError
      const axiosError = error as AxiosError<AxiosErrorResponse>; 
  
      if (axiosError.response) {
        // Server responded with a status other than 200
        console.error("Verify OTP error response:", axiosError.response.data);
        message.error(`Error: ${axiosError.response.data.message || "An error occurred."}`);
      } else {
        message.error("An error occurred during OTP verification.");
      }
      console.error("Verify OTP error:", error);
    }
    navigate('/resetpassword');
    
  };

  return (
    <section>
      <div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="login-1">
              <div className="login-logo">
                <img
                  src="/assets/logo.png"
                  className="img-fluid"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="login-form">
              <div  style={{width:"50%"}}>
                <h2 className="head-txt text-center">One Time Password</h2>
                <h6 className="login-txt-1">OTP Verification</h6>
                <p className="login-txt-2">
                  Enter OTP code sent to your s****@***.in
                </p>

                <div className="login-div mt-5">
                  <label>Enter your OTP</label>
                  <br />
                  <div className="otp-combine">
                    {otp.map((digit, index) => (
                      <Input
                        key={index}
                        id={`otp-input-${index}`}
                        className="otp-input"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleOtpInput(e.target.value, index)}
                      />
                    ))}
                  </div>
                </div>

                <div className="d-flex gap-2">
                  <p className="login-txt-2">Didn’t Receive? Resend OTP</p>
                  {isResendDisabled && <p className="login-txt-2">00:{String(timer).padStart(2, '0')}</p>}
                </div>

                <div className="d-flex justify-content-center mt-5">
                  {/* <Button className="btn-1" onClick={adminForgotPassword} disabled={isResendDisabled}>Reset</Button> */}
                  <Button className="btn-1" onClick={handleVerifyOTP}>Verify</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OTP;
