import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import { Select, SelectChangeEvent } from "@mui/material";
import { API_URL } from "../Api/Config";
import axios from "axios";
import { Empty } from "antd"; // Import Empty component from Ant Design

interface DataType {
  key: React.Key;
  Orderid: string;
  OrderedDate: string;
  Productname: string;
  specs: string;
  TotalPrize: string;
  mrpPrice: string;
  Shippingaddress: string;
  name: string;
  Payment: string;
  orderStatus: string;
}

const TodayOrder = () => {
  const [data, setData] = useState<DataType[]>([]); // Data for today's orders

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/orders?status=ordered`);
        const orders = response.data?.data?.orders;
    
        if (Array.isArray(orders)) {
          // Sort orders by createdAt in descending order (most recent first)
          const sortedOrders = orders.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    
          // Get the last 10 orders
          const lastTenOrders = sortedOrders.slice(0, 10);
    
          // Transform data
          const transformedData = lastTenOrders.map((order) => {
            const shippingAddress = order.shippingAddress || {}; // Fallback to empty object if undefined
    
            return {
              key: order._id,
              Orderid: order._id,
              OrderedDate: new Date(order.orderDate).toLocaleDateString(), // Format date
              Productname: order.products[0]?.productName || "N/A",
              specs: `${order.products[0]?.storage || ""} / ${order.products[0]?.colorName || ""}`,
              TotalPrize: order.finalAmount,
              mrpPrice: order.totalAmount,
              Shippingaddress: `${shippingAddress.houseNumberAndFloor || ""}, ${shippingAddress.buildingAndBlockNumber || ""}, ${shippingAddress.locality || ""}, ${shippingAddress.pincode || ""}`,
              name: `${shippingAddress.firstName || ""} ${shippingAddress.lastName || ""}`,
              Payment: order.paymentMethod,
              orderStatus: order.status,
            };
          });
    
          setData(transformedData);
        } else {
          console.error("Orders response is not in expected format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    
    

    fetchData();
  }, []);

  return (
    <>
      <section className="section-main">
        <div>
          <h2 className="head-txt">Latest Orders</h2>
          <div className="pt-2">
            {data.length === 0 ? (
              <Empty description="No orders for today" />
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="table-heads">
                        Order id
                      </TableCell>
                      <TableCell align="left" className="table-heads">
                        Ordered Date
                      </TableCell>
                      <TableCell align="left" className="table-heads">
                        Product name
                      </TableCell>
                      <TableCell align="left" className="table-heads">
                        Total Price
                      </TableCell>
                      <TableCell align="left" className="table-heads">
                        Shipping address
                      </TableCell>
                      <TableCell align="left" className="table-heads">
                        Payment
                      </TableCell>
                      <TableCell align="left" className="table-heads">
                        Order Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => (
                      <TableRow
                        key={row.Orderid}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" align="left">
                          {row.Orderid.slice(-5)}
                        </TableCell>
                        <TableCell align="left">{row.OrderedDate}</TableCell>
                        <TableCell align="left">
                          <div>
                            <div>{row.Productname}</div>
                            <div className="table-txt-s">{row.specs}</div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div>
                            <div>&#8377; {row.TotalPrize}</div>
                            {/* <div className="table-txt-s">{row.mrpPrice}</div> */}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div>
                            <div>{row.name}</div>
                            <div className="table-txt-s">{row.Shippingaddress}</div>
                          </div>
                        </TableCell>
                        <TableCell align="left">{row.Payment}</TableCell>
                        <TableCell align="left">{row.orderStatus}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TodayOrder;
