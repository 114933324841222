import React from "react";
import { Container } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Smartphone from "../Productlist/Smartphone";
import TabsPhone from "../Productlist/Tabs";
import Smartwatch from "../Productlist/Smartwatch";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Productlist = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
        <Container>
          <div>
            <h2 className="head-txt">Products</h2>

            <Box sx={{ width: "100%" }}>
              {/* <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Smart Phone" {...a11yProps(0)} />
                  <Tab label="Tabs"  {...a11yProps(1)} />
                  <Tab label="Laptops" {...a11yProps(2)} />
                  <Tab label="Smart watch" {...a11yProps(2)} />
                  <Tab label="TV" {...a11yProps(4)} />
                  <Tab label="Refrigerator" {...a11yProps(5)} />
                </Tabs>

               
              </Box> */}
              <CustomTabPanel value={value} index={0}>
                <Smartphone />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <TabsPhone />
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={2}>
                <Laptops /> */}
              {/* </CustomTabPanel> */}
              <CustomTabPanel value={value} index={2}>
                <Smartwatch />
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={4}>
                <Tv />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={5}>
                <Refridgerator />
              </CustomTabPanel> */}
            </Box>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Productlist;
