import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { Input, message } from "antd";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../Api/Config";
import { IoCaretBackOutline } from "react-icons/io5";

const DomainEdit = () => {
  const { domainId } = useParams<{ domainId: string }>();
  const [domainDetails, setDomainDetails] = useState({
    companyName: "",
    domainName: "",
    location: "",
    contactPersonName: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!domainId) {
      message.error("Domain ID is missing, redirecting to domain list...");
      navigate("/settings/domain");
      return;
    }

    const fetchDomain = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/domains/${domainId}`,{
          headers:{
             Authorization:`Bearer ${localStorage.getItem("dashtoken")}`
          }
        });
        setDomainDetails(response.data.domain);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching domain:", error);
        message.error("Failed to fetch domain details.");
        setLoading(false);
      }
    };

    fetchDomain();
  }, [domainId, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDomainDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleDomainUpdate = async () => {
    if (!domainId) {
      message.error("Domain ID is missing, unable to update domain.");
      return;
    }

    try {
      const response = await axios.patch(
        `${API_URL}/api/domains/${domainId}`,
        domainDetails,
        {
          headers: { "Content-Type": "application/json", Authorization:`Bearer ${localStorage.getItem("dashtoken")}` },
        }
      );

      if (response.status === 200) {
        message.success("Domain updated successfully!");
        navigate("/settings/domain");
      } else {
        message.error(response.data.message || "Domain update failed.");
      }
    } catch (error) {
      console.error("Error updating domain:", error);
      message.error("An error occurred while updating the domain.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
      <Container>
        <div>
       <div className="head-txt d-flex">
                   <a
                     onClick={() => window.history.back()}
                     className="icons-div"
                     style={{
                       textDecoration: "none",
                       cursor: "pointer",
                       color: "#000",
                     }}
                   >
                     <IoCaretBackOutline className="mb-1" />
                     <span>Edit Domain</span>
                   </a>
                 </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="mt-4">
                <label className="brand-label">Company Name*</label><br />
                <Input
                  className="brand-input"
                  name="companyName"
                  value={domainDetails.companyName}
                  onChange={handleChange}
                />
                <div className="mt-lg-3">
                  <label className="brand-label">Domain Name*</label><br />
                  <Input
                    className="brand-input"
                    name="domainName"
                    value={domainDetails.domainName}
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-lg-3">
                  <label className="brand-label">Location*</label>
                  <br />
                  <Input
                    className="brand-input"
                    name="location"
                    value={domainDetails.location}
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-lg-3">
                  <label className="brand-label">Contact Person Name*</label>
                  <Input
                    className="brand-input"
                    name="contactPersonName"
                    value={domainDetails.contactPersonName}
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-lg-3">
                  <label className="brand-label">Phone No*</label><br />
                  <Input
                    className="brand-input"
                    name="phoneNumber"
                    value={domainDetails.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="button-settings">
              <Button className="btn-2" onClick={() => navigate("/domain")}>
                Cancel
              </Button>
              <Button onClick={handleDomainUpdate} className="btn-1">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default DomainEdit;
